














import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { EventStores } from '@/store/events';
import CustomEventForm from '@/components/custom-events/CustomEventForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    CustomEventForm,
  },
})
export default class EditEvents extends Vue {
  eventStore = EventStores;

  get event() {
    return this.eventStore?.event;
  }

  mounted() {
    this.eventStore.getEvent(Number(this.$route.params.id));
  }

  handleSubmit(payload: any) {
    this.eventStore.updateEvent({
      id: Number(this.$route.params.id),
      ...payload,
    });
  }
}
